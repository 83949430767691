import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { db } from "../firebase"

const regex = /\{([^\|\{\}]+)\;([^\|\{\}}]+)\}/g

function getMessage(text, name, salutationNames) {
  const many = salutationNames.length > 1

  return text
    .replace(regex, (match, p1, p2, offset, string) => {
      return many ? p2 : p1
    })
    .replace(/\{name\}/g, name)
    .replace(/\{salut\}/g, salutationNames.join(", "))
}

const Nachricht = styled(({ name, names, salut, className, text }) => (
  <div className={className}>
    <pre>{getMessage(text, name, salut)}</pre>
  </div>
))`
  margin: 20px 10px;
  padding: 0 0 10px;
  border-bottom: 1px solid #ddd;
`

export default function AllNachricht() {
  const [data, setData] = useState([])

  useEffect(() => {
    return db.collection("guests").onSnapshot(snap => {
      const items = []
      snap.forEach(doc => {
        items.push(doc.data())
      })
      setData(items)
    })
  }, [])

  const [text, setText] = useState("")

  const messages = data.map(data => {
    return <Nachricht key={data.name} {...data} text={text} />
  })

  return (
    <div>
      <ul>
        <li>
          Verwende <strong>{`{salut}`}</strong> für Begrüßung
        </li>
        <li>
          Verwende <strong>{`{name}`}</strong> für den url namen z.B.
          sarah-und-lukas.com/{`{name}`}
        </li>
        <li>{`Verwendet {einzahl;mehrzahl}. z.B. {Dir;Euch}`} </li>
      </ul>
      <textarea onBlur={e => setText(e.target.value)} />
      <div>{messages}</div>
    </div>
  )
}
